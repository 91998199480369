<template>

  <div class="join">
    <div class="join-con">
      <h1 class="joinlogo"><img src="@/assets/img/boracaylogo.png" /></h1>
      <div class="join-in">
        <!--h2>{{ $t('front.common.signup') }}</h2-->
        <span class="jointxt">{{ $t('front.common.memId') }}</span>
        <div class="joininputwrap"><input class="joininput" type="text" v-model="model.memId" :readonly="true" @focus="onFocus" :placeholder="$t('front.common.memId')"/></div>
        <span class="jointxt2">{{ $t('front.signup.memIdPlaceholder') }}</span>

        <span class="jointxt">{{ $t('front.common.password') }}</span>
        <div class="joininputwrap"><input class="joininput" type="password" v-model="model.memPass" :placeholder="$t('front.common.password')"/></div>

        <span class="jointxt2">{{ $t('front.signup.passwordPlaceholder') }}</span>
        <span class="jointxt">{{ $t('front.mypage.passwordOk') }}</span>
        <div class="joininputwrap"><input class="joininput" type="password" v-model="model.memPassCheck" :placeholder="$t('front.mypage.passwordOk')"/></div>
        <span class="jointxt2">{{ $t('front.signup.passwordPlaceholder') }}</span>

        <span class="jointxt">{{ $t('front.common.cashOutPass') }}</span>
        <div class="joininputwrap"><input class="joininput" maxlength="4" inputmode="numeric" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  pattern="[0-9]*" id="cashOutPass" type="text" v-model="model.cashOutPass" :placeholder="$t('front.common.cashOutPass')"/>
        </div>
        <span class="jointxt2">{{ $t('front.signup.cashOutPassPlaceholder') }}</span>

        <span class="jointxt">{{ $t('front.common.nickName') }}</span>
        <div class="joininputwrap"><input class="joininput" type="text" v-model="model.memNick" :placeholder="$t('front.common.nickName')"/></div>
        <span class="jointxt2">{{ $t('front.signup.nickNamePlaceholder') }}</span>

        <span class="jointxt">{{ $t('front.common.phone') }}</span>
        <div class="joininputwrap"><input class="joininput" type="text" v-model="model.memPhone" :placeholder="$t('front.common.phone')"/></div>
        <span class="jointxt2">{{ $t('front.signup.phonePlaceholder') }}</span>
        <span class="jointxt">{{ $t('front.signup.recommandIdPlaceholder') }}</span>
        <div class="joininputwrap" v-if="!joinRecommenderId"><input class="joininput" type="text" v-model="model.recommenderId" :placeholder="$t('front.signup.recommandIdPlaceholder')"/></div>
        <div class="joininputwrap" v-else>{{joinRecommenderId}}</div>
        <span class="jointxt2">{{ $t('front.common.recommandId') }}</span>
        <span class="jointxt">{{ $t('front.common.bankSelect') }}</span>
        <div class="joinin">
          <div class="joininputwrap">
             <select v-model="model.bank">
               <option selected :value="''" disabled>{{ $t('front.common.bankSelect') }}</option>
               <template v-for="item in bankList" v-bind:key="item.bankCode">
                 <option :value="item.bankCode">{{ item.bankNameKr }}</option>
               </template>
             </select>
          </div>
          <div class="joininputwrap"><input class="joininput" type="text" :placeholder="$t('front.common.bankName')"
                                            v-model="model.memName"/></div>
        </div>
        <span class="jointxt">{{ $t('front.common.bankAcc') }}</span>
        <div class="joininputwrap"><input class="joininput" type="number"
                                          :placeholder="$t('front.common.bankAccOnlyNumber')" v-model="model.bankacc"/>
        </div>
        <span class="jointxt2">{{ $t('front.common.bankAccOnlyNumber') }}</span>
        <div class="joininputwrap joinb">
           <a class="joinbtns" @click="onSubmit('mobileMain')">{{ $t('front.common.signup') }}</a>
        </div>
        <!--ul class="sns">
          <li><a><img src="@/assets_mobile/img/cacao.png"><span class="br">test1234</span></a></li>
          <li><a><img src="@/assets_mobile/img/telegram.png"><span class="bl">test1234</span></a></li>
        </ul-->

      </div>
    </div>
  </div>
</template>

<script>
import join from '@/components/member/Join'

export default {
  name: 'join',
  mixins: [join]
}
</script>
<style scoped>
#cashOutPass {
  -webkit-text-security: disc;
}
</style>
<style scoped src="@/styles_mobile/join.css"></style>
<style scoped src="@/styles_mobile/header.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
